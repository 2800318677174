<script>
  import { _, locale } from "svelte-i18n";
  import { push } from "svelte-spa-router";
  import DatesCustom from "../../components/dates-custom/component.svelte";
  export let dataRequest;
  export let propertyId;
  export let managementColors;

  let filterSvg = false;

  const styling = (node, vars) =>
    Object.entries(vars).forEach(([p, v]) => (node.style[p] = v));

  let validationSvg = () => {
    if (
      dataRequest.serviceImg &&
      dataRequest.serviceImg.split("/").find((sp) => sp == "img")
    )
      filterSvg = true;
  };

  let handleGoDetail = (data) => {
    if (data.type == "pickup") {
      if (data.status === "Waiting" || data.status === "On van") {
        push(`/properties/${propertyId}/pickup`);
      }
    } else {
      push(`/properties/${propertyId}/my-experience/${data.id}`);
    }
  };

  function getIcon(status) {
    return ["Delivered", "Finished", "Arrive", "Confirmed"].includes(status)
      ? { icon: "check_circle", style: "status-green__icon" }
      : status == "Processed"
        ? { icon: "watch_later", style: "status-yellow__icon" }
        : ["Cancelled", "Declined"].includes(status)
          ? { icon: "cancel", style: "status-red__icon" }
          : ["Accepted", "On van"].includes(status)
            ? { icon: "watch_later", style: "status-yellow__icon" }
            : ["Validate", "Waiting"].includes(status)
              ? { icon: "error", style: "" }
              : { icon: "", style: "" };
  }

  validationSvg();
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="card-request row"
  use:styling={{
    background:
      managementColors.general.backgroundColorCards || dataRequest.status,
  }}
  on:click={() => handleGoDetail(dataRequest)}
  on:keyup|preventDefault={() => null}
>
  {#if dataRequest.serviceName == "Create your request" && dataRequest.serviceImg}
    <div
      use:styling={{ background: managementColors.color }}
      class="card-request__logo"
    >
      <img
        class="card-request__logo__img--create svg-icon {managementColors
          .general.colorMode == 'white'
          ? 'card-request__logo__img--create--white'
          : managementColors.general.colorMode == 'dark'
            ? 'card-request__logo__img--create--dark'
            : ''}"
        src={dataRequest.serviceImg}
        alt=""
      />
    </div>
  {:else if dataRequest.outletPhoto}
    <div
      class="card-request__logo"
      use:styling={{ background: managementColors.general.bacgroundCardIcon }}
    >
      <img
        class="header-icon--completed"
        class:header-icon={true}
        src={dataRequest.outletPhoto}
        alt=""
      />
    </div>
  {:else if dataRequest.serviceImg}
    <div
      class="card-request__logo"
      use:styling={{ background: managementColors.general.bacgroundCardIcon }}
    >
      <img
        class={`${
          filterSvg ? managementColors.title : "header-icon--completed"
        } ${dataRequest.serviceImg.includes(".svg") ? "svg-icon" : " "}`}
        class:header-icon={true}
        src={dataRequest.serviceImg}
        alt=""
      />
    </div>
  {/if}

  <div class="card-request__title">
    <span
      class="card-request__title-text"
      use:styling={{ color: managementColors.general.colorLetter }}
    >
      {$_(dataRequest.outletName) || dataRequest.requestText}
    </span>
  </div>

  <div class="card-request__info col s12 m12">
    {#if dataRequest.comments && dataRequest.comments.length > 0}
      <div class="cont__icon--chat">
        <i
          use:styling={{ color: managementColors.general.colorLetter }}
          class="material-icons icon--chat--desktop"
        >
          forum
        </i>
        {#if dataRequest.comments.find((message) => !message.read)}
          <div class="card--message__badge--desktop" />
        {/if}
      </div>
    {/if}
    <div class="col s3 mob__cont__txt">
      <div
        class="status-chip {dataRequest.statusClassname || dataRequest.status}"
      >
        <i
          class="material-icons status-icon {getIcon(dataRequest.status).style}"
        >
          {getIcon(dataRequest.status).icon}
        </i>
        {#if dataRequest.status}
          <span class="chip--statusbase__text {$locale}">
            {$_(dataRequest.status)}
          </span>
        {:else}
          <span class="chip--statusbase__text {$locale}">
            {$_(dataRequest.statusName)}
          </span>
        {/if}
      </div>
    </div>

    <div class="card-request__info-date">
      <DatesCustom
        bind:date={dataRequest.executionDate}
        bind:colorLetterDates={managementColors.general.colorLetterDates}
      />
    </div>

    {#if dataRequest.comments && dataRequest.comments.length > 0}
      <div class="cont__mob__icon__chat">
        <i
          use:styling={{ color: managementColors.general.colorLetter }}
          class="material-icons icon--chat"
        >
          forum
        </i>
        {#if dataRequest.comments.find((message) => !message.read)}
          <div class="card--message__badge" />
        {/if}
      </div>
    {/if}
  </div>
</div>

<style>
  .card-request {
    height: auto;
    min-height: 60px;
    box-shadow: 0px 4px 8px #00000029;
    border-radius: 4px;
    margin: 0px 0px 8px 0px;
    padding: 16px;
    opacity: 1;
    cursor: pointer;
  }

  .card-request__title {
    float: left;
    width: 84%;
    margin: 2px 0;
  }

  .card-request__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px #00000029;
    margin-right: 16px;
    border-radius: 4px;
    margin-top: 0px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    float: left;
    opacity: 1;
  }

  .card-request__title-text {
    font-size: 0.875rem;
    font-weight: 700;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    padding-top: 5px;
  }

  .card-request__number {
    margin-bottom: 9px;
  }

  .card-request__number-text {
    font-size: 0.875rem;
  }

  .card-request__info-date {
    margin-left: 8px;
  }

  .badge {
    width: 15px;
    height: 15px;
    background: #ff4855 0% 0% no-repeat padding-box;
    border-radius: 50%;
    position: relative;
    float: right;
    display: flex;
    top: 3px;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }

  .icon--chat {
    float: right;
    font-size: 1rem;
    position: relative;
    top: 7px;
    left: 10px;
  }

  .icon--chat--desktop {
    display: none;
  }

  .card--message__badge--desktop {
    display: none;
  }

  .header-icon--completed {
    object-fit: cover;
  }

  .card-request__logo__img--create {
    width: 28px;
    height: 28px;
    border-radius: 4px !important;
    opacity: 1;
  }

  .card-request__logo__img--create--white {
    filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(23deg)
      brightness(110%) contrast(96%) !important;
  }

  .card-request__logo__img--create--dark {
    filter: invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg)
      brightness(102%) contrast(102%) !important;
  }

  .chip--statusbase__text {
    font-size: 0.75rem;
    font-weight: 700;
    color: #212121;
    text-transform: uppercase;
    margin-top: 0px;
  }

  .status-white,
  .Validate {
    background: #f4f4f4;
  }
  .status-yellow,
  .Accepted {
    background: #ffeddb;
  }
  .status-red,
  .Cancelled,
  .Declined {
    background: #ffdadd;
  }
  .status-green,
  .Confirmed {
    background: #ccf5f4;
  }
  .status-chip {
    display: flex;
    align-items: center;
    width: auto;
    float: left;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 1.125rem;
    opacity: 1;
  }
  .status-white__icon {
    color: #686c6d;
  }
  .status-icon {
    font-size: 1.143rem;
    margin-right: 4px;
    opacity: 1;
  }
  .status-accepted__icon {
    color: #ffa64d;
  }
  .status-yellow__icon {
    color: #ffa64d;
  }
  .status-red__icon {
    color: #ff4855;
  }
  .status-green__icon {
    color: #1ab394;
  }
  .card-request__info {
    display: flex;
    padding: 6px 0px 0px 0px !important;
  }

  .header-icon {
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 4px !important;
    opacity: 1;
    cursor: pointer;
  }

  .cont__icon--chat {
    position: relative;
    width: 42%;
  }

  .cont__mob__icon__chat {
    position: relative;
  }

  .card--message__badge {
    display: block;
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #ff4855;
    top: 0;
    right: -15px;
  }

  .svg-icon {
    padding: 4px;
  }

  @media only screen and (max-width: 703px) {
    .col .row {
      margin-left: 0.75rem !important;
      margin-right: 0.75rem !important;
    }

    .cont__icon--chat {
      display: none;
    }
    .mob__cont__txt {
      margin-left: 0px !important;
      padding: 0px;
      width: fit-content !important;
    }
  }

  @media only screen and (min-width: 703px) {
    .row .col.s3 {
      margin-left: 16px;
    }
    .card-request__title-text {
      width: 100%;
    }

    .card-request__title-text {
      font-size: 1rem;
    }

    .card-request__number {
      margin-bottom: 0px;
      margin-top: 5px;
    }

    .card-request__info {
      justify-content: flex-end;
      align-items: center;
      float: right !important;
      width: max-content !important;
      margin-top: 9px;
      padding: 0px 0px 0px 0px !important;
    }

    .chip--statusbase {
      margin-left: 24px;
    }

    .icon--chat {
      display: none;
    }

    .card--message__badge {
      display: none;
    }

    .icon--chat--desktop {
      display: block;
      float: right;
      font-size: 1rem;
      position: relative;
      top: 5px;
      margin-right: 0px;
    }

    .card--message__badge--desktop {
      display: block;
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: #ff4855;
      top: 0px;
      right: -6px;
    }

    .card-request__info-date {
      top: 0px;
    }

    .cont__icon--chat {
      display: block;
    }

    .mob__cont__txt {
      margin-right: 5px !important;
      padding: 0px;
      width: max-content !important;
      display: flex;
      justify-content: flex-end;
    }

    .cont__icon--chat {
      width: 0%;
    }
    .card-request__logo {
      margin-top: 4px;
    }

    .card-request__title {
      margin: 2px 0;
      width: 54%;
    }
  }

  @media only screen and (min-width: 842px) {
    .row .col.s3 {
      margin-left: 16px;
    }

    .card-request__title {
      margin: 2px 0;
      width: 64%;
    }
    .card-request__title-text {
      width: 100%;
    }

    .card-request__title-text {
      font-size: 1rem;
    }

    .card-request__number {
      margin-bottom: 0px;
      margin-top: 5px;
    }

    .card-request__info {
      justify-content: flex-end;
      align-items: center;
      float: right !important;
      width: max-content !important;
      margin-top: 9px;
      padding: 0px 0px 0px 0px !important;
    }

    .chip--statusbase {
      margin-left: 24px;
    }

    .icon--chat {
      display: none;
    }

    .card--message__badge {
      display: none;
    }

    .icon--chat--desktop {
      display: block;
      float: right;
      font-size: 1rem;
      position: relative;
      top: 5px;
      margin-right: 0px;
    }

    .card--message__badge--desktop {
      display: block;
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: #ff4855;
      top: 0px;
      right: -6px;
    }

    .card-request__info-date {
      top: 0px;
    }

    .cont__icon--chat {
      display: block;
    }

    .mob__cont__txt {
      margin-right: 5px !important;
      padding: 0px;
      width: max-content !important;
      display: flex;
      justify-content: flex-end;
    }

    .cont__icon--chat {
      width: 0%;
    }
    .card-request__logo {
      margin-top: 4px;
    }
  }

  @media only screen and (min-width: 925px) {
    .row .col.s3 {
      margin-left: 16px;
    }

    .card-request__title {
      margin: 2px 0;
      width: 69%;
    }
    .card-request__title-text {
      width: 100%;
    }

    .card-request__title-text {
      font-size: 1rem;
    }

    .card-request__number {
      margin-bottom: 0px;
      margin-top: 5px;
    }

    .card-request__info {
      justify-content: flex-end;
      align-items: center;
      float: right !important;
      width: max-content !important;
      margin-top: 9px;
      padding: 0px 0px 0px 0px !important;
    }

    .chip--statusbase {
      margin-left: 24px;
    }

    .icon--chat {
      display: none;
    }

    .card--message__badge {
      display: none;
    }

    .icon--chat--desktop {
      display: block;
      float: right;
      font-size: 1rem;
      position: relative;
      top: 5px;
      margin-right: 0px;
    }

    .card--message__badge--desktop {
      display: block;
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: #ff4855;
      top: 0px;
      right: -6px;
    }

    .card-request__info-date {
      top: 0px;
    }

    .cont__icon--chat {
      display: block;
    }

    .mob__cont__txt {
      margin-right: 5px !important;
      padding: 0px;
      width: max-content !important;
      display: flex;
      justify-content: flex-end;
    }

    .cont__icon--chat {
      width: 0%;
    }
    .card-request__logo {
      margin-top: 4px;
    }
  }

  @media only screen and (min-width: 992px) {
    .card-request__title-text {
      font-size: 1.125rem;
    }

    .card-request__title-text {
      width: 100%;
    }
    .card-request__logo {
      margin-top: 7px;
    }

    .card-request__number-text {
      font-size: 1rem;
    }

    .card-request__info {
      width: max-content !important;
      margin-top: 11px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .card-request__title {
      width: 63%;
    }
  }

  @media only screen and (min-width: 1201px) and (max-width: 1450px) {
    .card-request__title {
      width: 60%;
    }
  }
</style>

import environments from "./environments";

let eventSource;

const listRoutesFeatures = [
  {
    codename: "home",
    route: "/properties/:propertyId",
  },
  {
    codename: "property.details",
    route: "/properties/:propertyId/information",
  },
  {
    codename: "restaurants.list",
    route: "/properties/:propertyId/restaurants",
  },
  {
    codename: "room-service.list",
    route: "/properties/:propertyId/room-services",
  },
  {
    codename: "myrequests",
    route: "/properties/:propertyId/my-experiences",
  },
  {
    codename: "requests.create",
    route: "/properties/:propertyId/create-requests",
  },
  {
    codename: "pickup",
    route: "/properties/:propertyId/pickup",
  }
];

const list = (input, lang, hidden) =>
  fetch(
    `${environments.reservations.api}/properties/${input.propertyId}/guests-features` +
    (hidden ? `?hidden=${hidden}` : ""),
    {
      method: "GET",
      headers: {
        language: lang,
      },
    }
  ).then((response) => response.json());

const get = (input, lang) =>
  fetch(
    `${environments.reservations.api}/properties/${input.propertyId}/guestapp-feature/${input.featureId}`,
    {
      method: "GET",
      headers: {
        language: lang,
      },
    }
  ).then((response) =>
    response.status == "200" ? response.json() : response.status
  );

const guestAppSettings = (input) =>
  fetch(
    `${environments.reservations.api}/properties/${input.propertyId}/guestapp-settings`
  ).then((response) => response.json());

const proppertyInformation = (input) =>
  fetch(`${environments.reservations.api}/properties/${input.propertyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": JSON.parse(localStorage.getItem('language'))
      }
    }
  ).then(
    (response) => (response.status == "200" ? response.json() : response.status)
  );

const validateRequestThePromotion = (propertyId, data) =>
  fetch(`${environments.reservations.api}/${propertyId}/promotion/${data.outletId}/validate${data.reservationId ? `?reservationId=${data.reservationId}` : ``}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "language": JSON.parse(localStorage.getItem('language'))
    },
  }).then((response) => response.ok ? response.json() : Promise.reject(response));

const markOpenMessageBy = (propertyId, input) =>
  fetch(`${environments.reservations.api}/${propertyId}/workflow/message${input.messageId ? `?messageId=${input.messageId}&channel=${input.channel}` : ''}`, {
    method: "PUT",
    body: JSON.stringify(input.body || {}),
    headers: {
      "Content-Type": "application/json",
      "language": JSON.parse(localStorage.getItem('language'))
    },
  }).then((response) => response.ok ? response : Promise.reject(response));

const createNewRequestThePromotion = (propertyId, data) =>
  fetch(`${environments.reservations.api}/${propertyId}/promotion/${data.outletId}${data.reservationId ? `?reservationId=${data.reservationId}` : ``}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "language": JSON.parse(localStorage.getItem('language'))
    },
  }).then((response) => response.ok ? response.json() : Promise.reject(response));

const createRequest = (propertyId, data) =>
  fetch(`${environments.reservations.api}/properties/${propertyId}/request`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "guesthub-context": JSON.stringify({
        properties: [propertyId],
      }),
      "language": JSON.parse(localStorage.getItem('language'))
    },
  }).then((response) => response.json());


const eventsEndpoint = (input) => {

  var loading = true;
  const requireIdUnique = localStorage.getItem("browser-identify");

  eventSource = new window["EventSource"](
    `${environments.events.api}/${input.propertyId}/guestapp-events?${input.reservationId
      ? "reservationId=" + input.reservationId
      : "unique=" + requireIdUnique
    }`
  );

  eventSource.onerror = function (event) {
    if (!loading) {
      eventSource.close();
      eventSource = undefined;
      setTimeout(
        function () {
          return eventsEndpoint(input);
        },
        2 * 5000
      );
      loading = true;
    }
  };
  loading = false;

  return {
    addEventListener: (event, callback) => {
      return eventSource.addEventListener(event, callback)
    },
  }
};

const requestList = (input) =>
  input.browserIdentify ?
    fetch(
      `${environments.reservations.api}/properties/${input.propertyId}/${input.reservationId}/requests?uniqueBrowser=${input.browserIdentify}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "language": JSON.parse(localStorage.getItem('language'))
        }
      }
    ).then((response) => response.json())
    : Promise.resolve([]);

const inStaFeedbackUpdateFinished = (input) =>
  fetch(
    `${environments.reservations.api}/${input.propertyId}/feedback/finished/${input.id}?${input.reservationId
      ? `confirmation=${input.reservationId}`
      : `uniqueBrowser=${input.browserIdentify}`
    }`,
    {
      method: "PUT",
      body: JSON.stringify(input.data),
      headers: {
        "Content-Type": "application/json"
      },
    }
  ).then((response) => response.ok || Promise.reject(response));

const inStaFeedbackUpdateSkiped = (input) =>
  fetch(
    `${environments.reservations.api}/${input.propertyId}/feedback/skiped/${input.id}?${input.reservationId
      ? `confirmation=${input.reservationId}`
      : `uniqueBrowser=${input.browserIdentify}`
    }`,
    {
      method: "PUT",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json"
      },
    }
  ).then((response) => response.ok || Promise.reject(response));

const inStaFeedbackDetail = (input) =>
  fetch(
    `${environments.reservations.api}/${input.propertyId}/feedback/${input.id}?${input.reservationId
      ? `confirmation=${input.reservationId}`
      : `uniqueBrowser=${input.browserIdentify}`
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": JSON.parse(localStorage.getItem('language'))
      }
    }
  ).then((response) => response.ok ? response.json() : Promise.reject(response));

const inStaFeedbackListActives = (input) =>
  fetch(
    `${environments.reservations.api}/${input.propertyId}/feedbacks/actives?${input.reservationId
      ? `confirmation=${input.reservationId}`
      : `uniqueBrowser=${input.browserIdentify}`
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": JSON.parse(localStorage.getItem('language'))
      }
    }
  ).then((response) => response.ok ? response.json() : Promise.reject(response));

const requestDetail = (input) =>
  fetch(
    `${environments.reservations.api}/properties/${input.propertyId}/${input.reservationId}/request/${input.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": JSON.parse(localStorage.getItem('language'))
      }
    }
  ).then((response) => response.json());

const commentsRequest = (propertyId, data, requestId) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/request/${requestId}/comments`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "guesthub-context": JSON.stringify({
          properties: [propertyId],
        }),
      },
    }
  ).then((response) => (response ? response : true));

const readMessage = (propertyId, data, requestId) =>
  fetch(
    `${environments.reservations.api}/properties/${propertyId}/request/${requestId}/comments`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "guesthub-context": JSON.stringify({
          properties: [propertyId],
        }),
      },
    }
  ).then((response) => (response ? response : true));

const menuRoomServicesList = (input) =>
  fetch(
    environments.reservations.api +
    "/properties/" +
    input.propertyId +
    "/food-beverage/menus/room-services?state=all&enabled=true&currentDay=" +
    (new Date().getDay() < 10
      ? "0" + new Date().getDay()
      : new Date().getDay()) +
    "&currentHours=" +
    (new Date().getHours() < 10
      ? "0" + new Date().getHours()
      : new Date().getHours()) +
    ":" +
    (new Date().getMinutes() < 10
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes()),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => response.json());

const menuRestaurantsList = (input) =>
  fetch(
    environments.reservations.api +
    "/properties/" +
    input.propertyId +
    "/food-beverage/menus?state=all&enabled=true&currentDay=" +
    (new Date().getDay() < 10
      ? "0" + new Date().getDay()
      : new Date().getDay()) +
    "&currentHours=" +
    (new Date().getHours() < 10
      ? "0" + new Date().getHours()
      : new Date().getHours()) +
    ":" +
    (new Date().getMinutes() < 10
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes()),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => response.json());

const outletDetail = (input) =>
  fetch(`${environments.reservations.api}/${input.propertyId}/${input.outleType}/${input.id}?${input.reservationId
    ? `reservationId=${input.reservationId}&`
    : ``
    }currentDay=${(new Date().getDay() < 10
      ? "0" + new Date().getDay()
      : new Date().getDay())}&currentHours=${(new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours())}:${(new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes())}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": input.language
      }
    }
  ).then((response) => response.ok ? response.json() : Promise.reject({ code: 404 }));

const outletContentDetail = (input) =>
  fetch(`${environments.reservations.api}/${input.propertyId}/${input.outleType}/${input.id}/${input.idContent}?currentDay=${(new Date().getDay() < 10
    ? "0" + new Date().getDay()
    : new Date().getDay())}&currentHours=${(new Date().getHours() < 10
      ? "0" + new Date().getHours()
      : new Date().getHours())}:${(new Date().getMinutes() < 10
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes())}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": input.language
      }
    }
  ).then((response) => response.ok ? response.json() : Promise.reject({ code: 404 }));

const outletContentMenuDetail = (input) =>
  fetch(`${environments.reservations.api}/${input.propertyId}/${input.outleType}/${input.id}/${input.idContent}/${input.idMenu}?currentDay=${(new Date().getDay() < 10
    ? "0" + new Date().getDay()
    : new Date().getDay())}&currentHours=${(new Date().getHours() < 10
      ? "0" + new Date().getHours()
      : new Date().getHours())}:${(new Date().getMinutes() < 10
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes())}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": input.language
      }
    }
  ).then((response) => response.ok ? response.json() : Promise.reject({ code: 404 }));

const outletsLists = (input) =>
  fetch(`${environments.reservations.api}/${input.propertyId}/${input.outleType}?${input.reservationId
    ? `reservationId=${input.reservationId}&`
    : ``
    }currentDay=${(new Date().getDay() < 10
      ? "0" + new Date().getDay()
      : new Date().getDay())}&currentHours=${(new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours())}:${(new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes())}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "language": input.language
      }
    }
  ).then((response) => response.ok ? response.json() : Promise.reject({ code: 400 }));

const getRouteFeature = (codename, propertyId, featureId, itemName) => {
  let routeLink = listRoutesFeatures.find((fet) => fet.codename == codename);

  if (routeLink) {
    routeLink = routeLink.route;
    if (routeLink.split(":propertyId")) {
      routeLink = routeLink.replace(":propertyId", propertyId);
    }
  } else {
    routeLink = `/properties/${propertyId}/feature/${featureId}/list-items`;
  }

  return routeLink;
};

const cancelRequest = (input, data) =>
  fetch(`${environments.reservations.api}/properties/${input.propertyId}/${input.reservationId}/request/${input.id}/cancelled`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "guesthub-context": JSON.stringify({
        properties: [input.propertyId],
      }),
    },
  }).then(() => '204');

const findComponents = (fontFamily) => {
  document.getElementsByTagName('body')[0].style.fontFamily = fontFamily;
  for (let i = 0; i <= document.getElementsByTagName('input').length; i++) {
    document.getElementsByTagName('input')[i] ?
      document.getElementsByTagName('input')[i].style.fontFamily = fontFamily :
      null;
  }

  for (let i = 0; i <= document.getElementsByTagName('button').length; i++) {
    document.getElementsByTagName('button')[i] ?
      document.getElementsByTagName('button')[i].style.fontFamily = fontFamily :
      null;
  }

  for (let i = 0; i <= document.getElementsByTagName('textarea').length; i++) {
    document.getElementsByTagName('textarea')[i] ?
      document.getElementsByTagName('textarea')[i].style.fontFamily = fontFamily :
      null;
  }
}

const setFontFamily = (fontFamily) => {

  setTimeout(() => {
    if (fontFamily) {
      findComponents(fontFamily);
      localStorage.setItem("fontFamily", JSON.stringify(fontFamily));
    } else {
      let fontFamily = JSON.parse(localStorage.getItem("fontFamily"));
      if (fontFamily) {
        findComponents(fontFamily);
      }
    }
  }, 50);
}

export default {
  list,
  get,
  guestAppSettings,
  proppertyInformation,
  createRequest,
  markOpenMessageBy,
  createNewRequestThePromotion,
  validateRequestThePromotion,
  requestList,
  inStaFeedbackListActives,
  inStaFeedbackDetail,
  inStaFeedbackUpdateSkiped,
  inStaFeedbackUpdateFinished,
  eventsEndpoint,
  requestDetail,
  commentsRequest,
  readMessage,
  menuRoomServicesList,
  menuRestaurantsList,
  getRouteFeature,
  cancelRequest,
  setFontFamily,
  outletsLists,
  outletDetail,
  outletContentDetail,
  outletContentMenuDetail
};
